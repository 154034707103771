<style scoped>
  .smiley {
    margin-right: 34px;
  }
  @media (max-width: 570px) {
    .smiley {
      margin-right: 13px;
    }
  }
</style>
<template>
  <div style="width: 100%; max-width: 550px; margin-left: auto; margin-right: auto; padding-left: 15px; padding-right: 15px;">
    <table cellspacing="0" cellpadding="0" style="font-size: 14pt; padding-right: 25px;">
      <tr style="position: fixed; height: 100px; background: #fff; padding-bottom: 20px;">
        <td colspan="5" style="width: 100%; max-width: 550px;">
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <br><br>
        <div style="float: right; margin-top: 40px;">
          <div style="margin-right: 20px; float: left;">😟</div>
          <div class="smiley" style="float: left;">😊</div>
        </div>
          <div>
            <div style="float: left;">
              <div style="float: left; margin-right: 5px; margin-top: 7px; width: 10px; height: 10px; background: #06c;"></div>
              <span v-if="data[0] && polars[0] && data[0][polars[0]][0] && data[0][polars[0]][0]['n']">
                {{ data[0][polars[0]][0]['n'] }}
              </span>
            </div>
            <br>
            <div style="float: left;">
              <div style="float: left; margin-right: 5px; margin-top: 7px; width: 10px; height: 10px; background: #900;"></div>
              <span v-if="data[0] && polars[0] && data[0][polars[0]][1] && data[0][polars[0]][1]['n']">
                {{ data[0][polars[0]][1]['n'] }}
              </span>
            </div>
            <br>
            <div style="float: left;">
              <div style="float: left; margin-right: 5px; margin-top: 7px; width: 10px; height: 10px; background: #090;"></div>
              <span v-if="data[0] && polars[0] && data[0][polars[0]][2] && data[0][polars[0]][2]['n']">
                {{ data[0][polars[0]][2]['n'] }}
              </span>
            </div>
          </div>
          <br><br><br>
        </td>
      </tr>
      <tr style="height: 150px; ">
        <td></td>
      </tr>
      <tr v-for="one in data">
        <td>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <br>
          {{ Object.keys(one)[0] }}
        </td>
        <td>
          <oneline :color="'blue'" :one="one[Object.keys(one)[0]][0]"></oneline>
        </td>
        <td>
          <oneline :color="'red'" style="margin-left: -58px;"  :one="one[Object.keys(one)[0]][1]"></oneline>
        </td>
        <td>
          <oneline :color="'green'" style="margin-left: -58px;" :one="one[Object.keys(one)[0]][2]"></oneline>
        </td>
      </tr>
    </table>
    <br><br>
    <br><br>
    <button @click="invite()" style="float: left; margin-left: 15px; width: 80%; height: 50px; font-size: 15pt;">Invite another player</button>
    <br><br>
    <br><br>
    <br><br>
    <button @click="copyMe()" style="float: left; margin-left: 15px; width: 80%; height: 50px; font-size: 15pt;">Copy my answers to new</button>
    <br><br>
    <br><br>
    <br><br>
    <button @click="copyAll()" style="float: left; margin-left: 15px; width: 80%; height: 50px; font-size: 15pt;">Copy all answers to new </button>
    <br><br>
    <br><br>
    <br><br>
    <button @click="frontPage()" style="float: left; margin-left: 15px; width: 80%; height: 50px; font-size: 15pt;">Start from beginning</button>
    <br><br>
    <br><br>
  </div>
</template>
<script>

import Typed from 'typed.js'
import axios from 'axios'
import polars from './polars'
import oneline from './Oneline'

export default {
  name: 'Index',
  data() {
    return {
      data: '',
      names: []
    }
  },
  computed: {
    polars() {
      return JSON.parse(JSON.stringify(polars))
    },
    id() {
      return this.$route.params.id
    },
    user() {
      return this.$store.user || localStorage.getItem('user')
    }
  },
  components: {
    oneline,
  },
  mounted() {
    this.get()
  },
  methods: {
    frontPage () {
      this.$router.push('/')
    },
    invite () {
      this.$router.push('/invite/' + this.id + '/' + this.$store.user || localStorage.getItem('user'))
    },
    copyMe () {
      const t = this
      axios.post('/copy-me/' + this.id + '/' + this.user)
        .then(function (initialData) {
          const data = initialData.data
          const newId = data.id
          t.$router.push('/invite/' + newId + '/' + t.$store.user || localStorage.getItem('user'))
        })
        .catch(function (err) {
          alert (err)
        })
    },
    copyAll () {
      const t = this
      axios.get('/copy/' + this.id)
        .then(function (initialData) {
          const data = initialData.data
          const newId = data.id
          t.$router.push('/invite/' + newId + '/' + t.$store.user || localStorage.getItem('user'))
        })
        .catch(function (err) {
          alert (err)
        })
    },
    get() {
      const t = this
      axios.get('/get/' + this.id)
        .then(function (initialData) {

          const data = initialData.data

          let questions = {}
          let names = []

          for (let i = 0; i < data.length; i++) {
            const answer = data[i]
            if (answer.q) {
              if (!questions[answer.q]) {
                // crate user if does not exist
                questions[answer.q] = {}
              }
              questions[answer.q][answer.n] = answer.a
            }
            if (answer.n) {
              if (names.indexOf(answer.n) === -1) {
                names.push(answer.n)
              }
            }
          }

          t.names = names

          let response = []
          for (let i = 0; i < polars.length; i++) {
            const polar = polars[i]
            if (!response[polar]) response[polar] = []
            if (questions[polar]) {
              const userResponses = Object.keys(questions[polar])
              let oneResponse = {}
              oneResponse[polar] = []
              for (let j = 0; j < userResponses.length; j++) {
                const userName = userResponses[j]
                const oneUser = questions[polar][userName]
                if (oneUser) {
                  oneResponse[polar].push({ a: oneUser, n: userName })
                }
              }
              response.push(oneResponse)
            }
          }

          // OUTPUT:
          /*
          const u = [{
            'Pronouns': [{ a: ":)", n: 'aa'}, { a: ":)", n: 'bb' }],
          }]
          */
          t.data = response
        })
        .catch(function (err) {
          alert(err)
        })

    }
  },
  watch: {
    order() {
      this.makeTextGo()
    }
  }
}
</script>
